if (process.env.NODE_ENV === 'production') {
    module.exports = require('./configure-store.prod')
} else {
    module.exports = require('./configure-store.dev')
}

// import { createStore, applyMiddleware, compose } from 'redux'
// import thunk from 'redux-thunk'
// import optimusData from '../reducers/index'
// import { composeWithDevTools } from 'remote-redux-devtools'

// const composeEnhancers = composeWithDevTools({ name: 'ndaOK Redux State', realtime: true, port: 8000 })
// export default function configureStore(initialState) {
//     let devTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose
//     return createStore(
//         optimusData,
//         initialState,
//         composeEnhancers(
//             applyMiddleware(thunk),
//             devTools()
//         )
//     )
// }
