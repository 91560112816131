import { createSlice } from '@reduxjs/toolkit'
import * as helpers from './helpers'

const INITIAL_STATE = {
    timezones: {
        allTrucks: null,
        allStations: null,
        trucks: [],
        stations: []
    },
    emissionFormulas: {
        allStations: null,
        stations: []
    }
}

const uiSlice = createSlice({
    name: 'ui',
    initialState: INITIAL_STATE,
    reducers: {
        saveEntityTimezone(state, action) {
            let entityState = state.timezones[action.payload.entity]
            const objectIdx = helpers.getObjectIndex(entityState, action.payload.id)
            if (objectIdx === -1) {
                entityState.push({ id: action.payload.id, timezone: action.payload.tz })
            } else {
                entityState[objectIdx].timezone = action.payload.tz
            }
        },
        saveEntityEmissions(state, action) {
            let entityState = state.emissionFormulas[action.payload.entity]
            const objectIdx = helpers.getObjectIndex(entityState, action.payload.id)
            if (objectIdx === -1) {
                entityState.push({ id: action.payload.id, formula: action.payload.formula })
            } else {
                entityState[objectIdx].formula = action.payload.formula
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase('KILL_STATE', (state, action) => {
                return INITIAL_STATE
            })
    }
})
export const { saveEntityTimezone, saveEntityEmissions } = uiSlice.actions
export default uiSlice.reducer
