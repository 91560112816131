import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { withRouterProps } from '../../utils/with-router-props'
import { routes, apiResourceEndpoint } from '../../../constants/routes'
import { fetchApiDataIfNeeded } from '../../../actions/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from 'lodash'

const mapStateToProps = (state) => {
    return {
        account: state.account,
        auth: state.auth,
        role: state.role,
        user: state.user
    }
}

const AuthRouter = ({ dispatch, user, location }) => {
    const [readyToRoute, setReadyToRoute] = useState(false)
    const [ timedOut, setTimedOut ] = useState(false)

    useEffect(() => {
        if (isEmpty(user)) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('user/load-details', 'LIST')))
        }
        if (user && '_computed' in user) {
            setReadyToRoute(true)
        }
    }, [dispatch, user])

    const runTimer = setTimeout(() => {
        setTimedOut(true)
    }, 5000)

    useEffect(() => {
        return () => {
            clearTimeout(runTimer)
        }
    }, [runTimer])

    if (readyToRoute) {
        const accountRoleLevel = user._computed.roleLevel
        if (location.state && location.state.from.pathname) {
            return <Navigate to={location.state.from.pathname} />
        }
        // TODO: Check account type for where to send them, will have more later
        if (accountRoleLevel >= 1000) {
            return <Navigate to={routes.adminIndex.path} />
        }
        return <Navigate to={routes.appIndex.path} />
    }
    return (<>
        <div className="row justify-content-center">
            <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="box text-center">
                    <span className="large-status-icon">
                        {timedOut ? (
                            <>
                                <h1 className="m-5 text-center">Something Went Wrong</h1>
                                <p className="m-5 text-center">
                                    <Link to={routes.appIndex.path} className="btn blue">Back to Homepage</Link>
                                </p>
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon="spinner" spin />
                                <h1 className="m-5 text-center">Heading to Your Account...</h1>
                            </>
                        )}
                    </span>
                </div>
            </div>
        </div>
    </>)
}

export default withRouterProps(connect(mapStateToProps)(AuthRouter))
