import { createSlice } from '@reduxjs/toolkit'
import * as helpers from './helpers'

const INITIAL_STATE = []

const formSlice = createSlice({
    name: 'forms',
    initialState: INITIAL_STATE,
    reducers: {
        upsertForm(state, action) {
            const objectIdx = helpers.getObjectIndex(state, action.payload.id)
            if (objectIdx === -1) {
                state.push(action.payload)
            } else {
                state[objectIdx] = action.payload
            }
        },
        clearForm(state, action) {
            state.filter(s => s.id !== action.payload.formId)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase('KILL_STATE', (state, action) => {
                return INITIAL_STATE
            })
    }
})

export const { upsertForm, clearForm } = formSlice.actions
export default formSlice.reducer
