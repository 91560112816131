export const fetchingStates = {
    EMPTY: '',
    FETCHING: 'FETCHING',
    ERROR: 'ERROR',
    COMPLETE: 'COMPLETE'
}

export const formStates = {
    EMPTY: '',
    PROCESSING: 'PROCESSING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS'
}
