import apiCacheReducer from './api-cache'
import authCodeReducer from './auth-code'
import authReducer from './auth'
import formReducer from './form'
import genericSlice from './generic-slice'
import pagerReducer from './pager'
import uiReducer from './ui'

const BLANK_OBJ = {}
const BLANK_LIST = []

const indexReducer = {
    account: genericSlice('ACCOUNT', BLANK_OBJ),
    accountEmissionFormula: genericSlice('ACCOUNT_EMISSION_FORMULA', BLANK_OBJ),
    adminAccounts: genericSlice('ADMIN_ACCOUNT', BLANK_LIST),
    adminAccountTypes: genericSlice('ADMIN_ACCOUNT_TYPES', BLANK_LIST),
    adminAccountEmissionFormulas: genericSlice('ADMIN_ACCOUNT_EMISSION_FORMULAS', BLANK_LIST),
    adminUsers: genericSlice('ADMIN_USER', BLANK_LIST),
    adminRoles: genericSlice('ADMIN_ROLE', BLANK_LIST),
    accountTypes: genericSlice('ACCOUNT_TYPES', BLANK_LIST),
    apiCaches: apiCacheReducer,
    auth: authReducer,
    authCode: authCodeReducer,
    configs: genericSlice('CONFIGS', BLANK_LIST),
    csvReportTemplates: genericSlice('CSV_REPORT_TEMPLATES', BLANK_LIST),
    csvReportMappings: genericSlice('CSV_REPORT_MAPPINGS', BLANK_LIST),
    dataTemplates: genericSlice('DATA_TEMPLATES', BLANK_LIST),
    emissionFormulas: genericSlice('EMISSION_FORMULAS', BLANK_LIST),
    faultDefinitions: genericSlice('FAULT_DEFINITIONS', BLANK_LIST),
    fields: genericSlice('FIELDS', BLANK_LIST),
    fieldMappings: genericSlice('FIELD_MAPPINGS', BLANK_LIST),
    fleets: genericSlice('FLEETS', BLANK_LIST),
    forms: formReducer,
    paginator: pagerReducer,
    realTimeTruckData: genericSlice('REAL_TIME_TRUCK_DATA', BLANK_LIST),
    reportStationFaults: genericSlice('REPORT_STATION_FAULTS', BLANK_LIST),
    reportTruckActivity: genericSlice('REPORT_TRUCK_ACTIVITY', BLANK_LIST),
    reportTruckFaults: genericSlice('REPORT_TRUCK_FAULTS', BLANK_LIST),
    roles: genericSlice('ROLE', BLANK_LIST),
    teams: genericSlice('TEAMS', BLANK_LIST),
    telematics: genericSlice('TELEMATICS', BLANK_LIST),
    testBenches: genericSlice('TEST_BENCHES', BLANK_LIST),
    trucks: genericSlice('TRUCKS', BLANK_LIST),
    trucksInstantData: genericSlice('TRUCK_INSTANT_DATA', BLANK_LIST),
    // TODO: trucksSnapShotData will go away soon
    trucksSnapshotData: genericSlice('TRUCK_SNAPSHOT_DATA', BLANK_LIST),
    truckEstops: genericSlice('TRUCK_ESTOPS', BLANK_LIST),
    truckEngineStates: genericSlice('TRUCK_ENGINE_STATES', BLANK_LIST),
    truckOffsets: genericSlice('TRUCK_OFFSETS', BLANK_LIST),
    truckSnapshots: genericSlice('TRUCK_SNAPSHOTS', BLANK_LIST),
    truckData: genericSlice('TRUCK_SIGNAL_DATA', BLANK_LIST),
    truckEngines: genericSlice('TRUCK_ENGINES', BLANK_LIST),
    truckIntervals: genericSlice('TRUCK_INTERVALS', BLANK_LIST),
    truckModels: genericSlice('TRUCK_MODELS', BLANK_LIST),
    truckMakes: genericSlice('TRUCK_MAKES', BLANK_LIST),
    truckPositions: genericSlice('TRUCK_POSITIONS', BLANK_LIST, 'uuid'),
    truckRFIDs: genericSlice('TRUCK_RFIDS', BLANK_LIST),
    truckSummaryData: genericSlice('TRUCK_SUMMARY_DATA', BLANK_LIST),
    stations: genericSlice('STATIONS', BLANK_LIST),
    stationTransactions: genericSlice('STATION_TRANSACTIONS', BLANK_LIST),
    stationFaults: genericSlice('STATION_FAULTS', BLANK_LIST),
    stationFaultStatusLogs: genericSlice('STATION_FAULT_STATUS_LOGS', BLANK_LIST),
    stationTransactionLogs: genericSlice('STATION_TRANSACTION_INTEGRITY_LOGS', BLANK_LIST),
    stationsInstantData: genericSlice('STATION_INSTANT_DATA', BLANK_LIST),
    stationIntervals: genericSlice('STATION_INTERVALS', BLANK_LIST),
    stationOffsets: genericSlice('STATION_OFFSETS', BLANK_LIST),
    stationPrices: genericSlice('STATION_PRICES', BLANK_LIST),
    stationSignalData: genericSlice('STATION_SIGNAL_DATA', BLANK_LIST),
    stationSnapshots: genericSlice('STATION_SNAPSHOTS', BLANK_LIST),
    stationSummaryData: genericSlice('STATION_SUMMARY_DATA', BLANK_LIST),
    ui: uiReducer,
    user: genericSlice('USER', BLANK_OBJ),
    vendors: genericSlice('VENDORS', BLANK_LIST),
    vinLookup: genericSlice('VIN_LOOKUP', BLANK_LIST)
}

export default indexReducer
