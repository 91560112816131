import React from 'react'
import { createBrowserHistory } from 'history'
import { useLocation, useNavigate, useParams, matchRoutes } from 'react-router-dom'
import { routes } from '../../constants/routes'
import NotFound from '../public/pages/not-found'

export const withRouterProps = (Component) => {
    const Wrapper = (props) => {
        const history = createBrowserHistory()
        const location = useLocation()
        const navigate = useNavigate()
        const params = useParams()
        let myRoutes = Object.keys(routes).map(r => ({ path: routes[r].path }))
        let matches = matchRoutes(myRoutes, location.pathname)
        if (!matches) {
            // Handle the case where no matching routes were found
            return <NotFound />
        }

        let locationParts = matches[0].route.path.split('/').filter(e => e)
        let pathNameParts = location.pathname.split('/').filter(e => e)
        let routeString = '/'
        let pathString = '/'
        let crumbs = []
        locationParts.forEach((loc, idx) => {
            routeString = routeString + loc + '/'
            pathString = pathString + pathNameParts[idx] + '/'
            Object.keys(routes).forEach(r => {
                let entityType = null
                let entityId = null
                // if (routes[r].path === routeString && pathString !== location.pathname) {
                if (routes[r].path === routeString) {
                    // If we're in the view path, we want to pass some extra info to the breadcrumb
                    if (routes[r].label === 'View') {
                        let typeParts = pathNameParts[idx].split('_')
                        entityType = typeParts[0]
                        entityId = pathNameParts[idx]
                    }
                    crumbs.push({ path: pathString, label: routes[r].label, entityType, entityId })
                }
            })
        })

        return (
            <Component
                history={history}
                location={location}
                navigate={navigate}
                params={{ ...params, matchedPath: matches[0].route.path }}
                crumbs={ crumbs }
                {...props}
            />
        )
    }
    return Wrapper
}
