import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {}

const pagerSlice = createSlice({
    name: 'paginator',
    initialState: INITIAL_STATE,
    reducers: {
        savePaginator(state, action) {
            state[action.payload.key] = action.payload.data
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase('KILL_STATE', (state, action) => {
                return INITIAL_STATE
            })
    }
})

export const { savePaginator } = pagerSlice.actions
export default pagerSlice.reducer
