import React from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../../constants/routes'

const NotFound = () => {
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="box text-center">
                        <h1 className="h3 m-5 text-center">Page not found.</h1>
                        <p className= "m-5 text-center">
                            <Link to={routes.publicIndex.path} className="btn blue">Back to Home</Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}


export default NotFound
