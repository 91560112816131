import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PageLoader = () => {
    return (
        <div className="row justify-content-center">
            <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="box text-center">
                    <span className="large-status-icon">
                        <FontAwesomeIcon icon="spinner" spin />
                        <h1 className="m-5 text-center">Loading content...</h1>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PageLoader
