import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {}

const authCodeSlice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
        // Give case reducers meaningful past-tense "event"-style names
        saveAuthHandler(state, action) {
            state.authCode = action.payload.code
            state.authAction = action.payload.action
            state.authTempEmail = action.payload.email
            state.isAnonymous = action.payload.isAnonymous
        },
        saveAuthHandlerError(state, action) {
            state.hasError = true
        },
        removeAuthHandler(state, action) {
            return INITIAL_STATE
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase('KILL_STATE', (state, action) => {
                return INITIAL_STATE
            })
    }
})
export const { saveAuthHandler, saveAuthHandlerError, removeAuthHandler } = authCodeSlice.actions
export default authCodeSlice.reducer
