import { configureStore } from '@reduxjs/toolkit'
import optimusData from '../reducers/index'

export default function initStore(initialState) {
    const store = configureStore({
        reducer: optimusData,
        preloadedState: initialState,
        devTools: false
    })

    return store
}
